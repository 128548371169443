<template>
  <el-dialog v-dialogDrag :title="title" :visible.sync="visible" width="1000px">
    <el-form class="form" ref="form" :model="form" :rules="rules" label-width="100px">
      <el-form-item label="应用名称" prop="appName">
        <el-input v-model="form.appName"></el-input>
      </el-form-item>
      <el-form-item label="版本" prop="appType">
        <el-select v-model="form.appType">
          <el-option label="安卓" :value="0"></el-option>
          <el-option label="IOS" :value="1"></el-option>
          <el-option label="PC" :value="2"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="版本号" prop="appVersion">
        <el-input v-model="form.appVersion"></el-input>
      </el-form-item>
      <el-form-item label="应用文件">
        <jf-ossUpload-file @result="getFile"/>
      </el-form-item>
      <el-form-item label="下载链接" prop="appDownloadLink">
        <el-input v-model="form.appDownloadLink"></el-input>
      </el-form-item>
      <el-form-item label="图标" prop="appIcon">
        <jf-ossUpload-img :value="form.appIcon" @result="(val)=>{form.appIcon=val}"></jf-ossUpload-img>
      </el-form-item>
      <el-form-item label="版本描述" prop="appVersionDesc">
        <el-input type="textarea" :rows="5" v-model="form.appVersionDesc" maxlength="300" show-word-limit></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button size="small" @click="$emit('update:show',false)">取 消</el-button>
      <el-button size="small" type="primary" :loading="loading" @click="submit">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    row: {
      type: Object,
      default() {
        return {};
      },
    },
  },

  data() {
    return {
      form: {},
      loading: false,
      editor: null,
      rules: {
        appName: [{required: true, message: "应用名称必填"}],
        appVersion: [{required: true, message: "版本号必填"}],
        appType: [
          {required: true, message: "应用版本必填", trigger: ["change"]},
        ],
        appDownloadLink: [{required: true, message: "应用下载链接必填"}],
        appVersionDesc: [{required: true, message: "版本描述必填"}],
      },
    };
  },

  computed: {
    visible: {
      get() {
        return this.show;
      },
      set(val) {
        this.$emit("update:show", val);
      },
    },
    title() {
      var title = "新增版本";
      if (this.form.id) {
        title = "修改版本";
      }
      return title;
    },
  },
  watch: {
    row: {
      handler(val) {
        this.initForm(val);
      },
      deep: true,
    },
  },
  created() {
    this.initForm(this.row);
  },
  mounted() {
  },
  methods: {
    getFile(url) {
      console.log(url);
      this.$set(this.form, "appDownloadLink", url);
    },
    initForm(val) {
      // 判断是否拿到表单元素，重置表单
      if (this.$refs.form) {
        this.$refs.form.resetFields();
        this.$forceUpdate();
      }
      // 每次add/edit操作 重置值
      if (val.id) {
        this.form = {...val};
      } else {
        this.form = {
          appDownloadLink: "",
          appIcon: "",
          appName: "",
          appType: null,
          appVersion: "",
          appVersionDesc: "",
        };
      }
    },
    // 获取上传成功图片 url
    getImg(e) {
      this.form.appIcon = e;
    },
    submit() {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          this.loading = true;
          let data = {...this.form};
          let res;
          if (data.id) {
            res = await this.$put("/platform-config/community/appControl", data);
          } else {
            res = await this.$post("/platform-config/community/appControl", data);
          }
          this.loading = false;
          if (res && res.code === 0) {
            var str = data.id ? "修改成功" : "添加成功";
            var ev = data.id ? "edit" : "add";
            this.$message.success(str);
            this.$emit(ev);
            this.$emit("update:show", false);
          }
        }
      });
    },
  },
};
</script>

<style>
.editor {
  width: 100%;
  height: 350px;
  padding-bottom: 20px;
}

.form {
  padding: 20px;
}
</style>
